import { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import LoginContext from 'utils/gatsby/context/login-status';
import { useFlagAwait } from 'utils/hooks/use-flagsawait';
import { FDT_285, FDT_285_DISPLAY_PLANS, FDT_387 } from 'utils/launch-darkly/experiments/constants';

import CustomerReviews from 'components/content-blocks/customer-reviews/customer-reviews';
import StructuredData from 'components/content-blocks/partials/structured-data';
import Calculator from 'components/content-blocks/roi-calculator';
import TwoColumnGrid from 'components/content-blocks/two-column-grid';
import PlanComparisonV2 from 'components/globals/plan-comparison-v2';
import Yoast from 'components/globals/yoast';
import { HeroPricing } from 'components/hero/pricing';
import PricingTiers from 'components/sliders/pricing-tiers';

import './styles/pricing.scss';

const Pricing = () => {
  const fdt285 = useFlagAwait(FDT_285);
  const fdt285DisplayPlans = useFlagAwait(FDT_285_DISPLAY_PLANS);
  const fdt387 = useFlagAwait(FDT_387);   // Free plan
  const { loggedIn } = useContext(LoginContext);
  const pricingFlagValue = loggedIn ? fdt285DisplayPlans : fdt285;
  const is2025pricing = pricingFlagValue === 'pricing-2025';

  const [activateAttendance, setActivateAttendance] = useState(false);
  const toggleAttendance = () => setActivateAttendance(!activateAttendance);

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "pricing" } }) {
        edges {
          node {
            wordpress_id
            yoast_head_json {
              title
              description
              canonical
              og_title
              og_description
              og_image {
                url {
                  source_url
                }
              }
              og_url
              og_type
              twitter_title
              twitter_description
              twitter_image {
                source_url
              }
            }
            acf {
              hero {
                sub_title
                copy
                background_desktop_image {
                  source_url
                  alt_text
                }
                schedule_demo {
                  button_text
                  button_image {
                    source_url
                  }
                }
                title
                signup {
                  modal_submit_button_text
                  modal_title
                  launch_modal_button_text
                }
              }

              basic_title_subtitle_copy_image {
                title
              }
              faq {
                question
                answer
                image {
                  source_url
                }
              }
              media_object {
                body
                sub_title
                title
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                button_url
                button_text
              }
            }
          }
        }
      }
      allWordpressAcfOptions {
        edges {
          node {
            options {
              packaging_comparison {
                title
                section {
                  header {
                    name
                    plan_1
                    plan_2
                    plan_3
                    plan_4
                  }
                  feature {
                    name
                    plan_1
                    plan_2
                    plan_3
                    plan_4
                  }
                }
                features_button_text_state_1
                features_button_text_state_2
              }
              structured_data {
                pricing
              }
              customer_reviews {
                title
                sub_title
                customer {
                  name
                  company
                  quote
                  image {
                    source_url
                  }
                }
              }
              pricing_cards {
                plans {
                  attendance_toggle
                  features {
                    heading
                    list {
                      feature
                    }
                  }
                  features_alt {
                    heading
                    list {
                      feature
                    }
                  }
                  for_who
                  price
                  price_label
                  price_with_attendance
                  title
                }
              }
              packaging_2025 {
                body
                heading
                link {
                  title
                  url
                }
                list {
                  feature
                  icon
                }
              }
              pricing_cards_2025 {
                plans {
                  features {
                    heading
                    list {
                      feature
                    }
                  }
                  highlights {
                    icon
                    title
                  }
                  price
                  price_label
                  title
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  let pricingCards = data.allWordpressAcfOptions.edges[0].node.options.pricing_cards.plans;
  if (!fdt387) {
    // slicing off the free plan at the start of the array
    pricingCards = data.allWordpressAcfOptions.edges[0].node.options.pricing_cards.plans.slice(1);
  }
  const packagingComparison = data.allWordpressAcfOptions.edges[0].node.options.packaging_comparison;
  const hero = pageContext.hero;
  const faq = pageContext.faq;
  const customerReviews = data.allWordpressAcfOptions.edges[0].node.options.customer_reviews;
  const structuredData = data.allWordpressAcfOptions.edges[0].node.options.structured_data.pricing;
  const answer2024Pricing = 'You can try When I Work through a fully-featured, free 14-day trial. When I Work offers three plans so you can choose what\'s best for your workplace. The Essentials plan starts at $1.50 per user, per month, the Pro plan starts at $3.00 per user, per month, and the Premium plan starts at $5.00 per user, per month with optional add ons available for all plans. When I Work is billed on a month-to-month basis or annually. If you are paying monthly, you can change your plan at any time.';
  const packaging2025 = data.allWordpressAcfOptions.edges[0].node.options.packaging_2025;
  const pricingCards2025 = data.allWordpressAcfOptions.edges[0].node.options.pricing_cards_2025.plans;

  useEffect(() => {
    if (pricingFlagValue === 'pricing-2024-v2') faq[5].answer = answer2024Pricing;
  }, [pricingFlagValue]);

  return (
    <Layout
      legalText="<p class='trial-terms'>By clicking <strong>Start Free Trial</strong> you agree to the <a href='/terms' target='_blank' rel='noopener'>Terms of Service</a>, <a href='/privacy' target='_blank' rel='noopener'>Privacy</a>, and <a href='/refund' target='_blank' rel='noopener'>Refund policies</a>.</p>"
      signupModalSubmit="Start Free Trial"
    >
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <HeroPricing { ...hero } pricingFlagValue={pricingFlagValue} />
      <div className="pricing-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <section
          className="pricing-cards-section section"
          data-section="pricingCard"
        >
          <div className={ classnames('container pricing-container-state pricing-container', { 'experiment-loading': pricingFlagValue === undefined }) }>
            <PricingTiers
              tiers={ is2025pricing ? pricingCards2025 : pricingCards }
              toggleAttendance={ toggleAttendance }
              activateAttendance={ activateAttendance }
              fdt285={ pricingFlagValue }
              fdt387={ fdt387 }
            />
          </div>
        </section>

        {!is2025pricing && 
          <PlanComparisonV2
            toggleAttendance={ toggleAttendance }
            activateAttendance={ activateAttendance }
            packagingComparison={ packagingComparison }
            fdt387={ fdt387 }
          />
        }

        {!is2025pricing && <Calculator />}

        {is2025pricing && <CustomerReviews { ...customerReviews } hasImage hasAwards /> }
        {is2025pricing && <TwoColumnGrid { ...packaging2025 } />}

        <section data-section="faq-section" className="faq-section section">
          <div className="container container-xlarge">
            <h2 className="faq-title">
              {pageContext.basic_title_subtitle_copy_image.title}
            </h2>
            <div className="faq-wrapper">
              <div className="faq-device">
                <div className="faq-device-top"></div>
                <div className="faq-device-content"> 
                  {faq.map((dialogue, index) => {
                    return (
                      <div key={ index } className="faq-row">
                        <div className="faq-item question is-flex">
                          <img
                            loading="lazy"
                            src={ dialogue.image.source_url }
                            alt="fill me out"
                          />
                          <h3 className="faq-question">{dialogue.question}</h3>
                        </div>
                        <div className="faq-item answer is-flex">
                          <p
                            className="faq-answers"
                            dangerouslySetInnerHTML={ {
                              __html: dialogue.answer,
                            } }
                          />
                          <img
                            loading="lazy"
                            src="https://marketing-assets.wheniwork-production.com/2019/12/04205359/icon-faq-wiw.svg"
                            alt="fill me out"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="faq-device-bottom"></div>
              </div>
              <div className="faq-circle yellow"></div>
              <div className="faq-circle green"></div>
            </div>
          </div>
        </section>

        {!is2025pricing && <CustomerReviews { ...customerReviews } hasImage hasAwards /> }
      </div>

      <StructuredData data={ structuredData } />
      
    </Layout>
  );
};

export default Pricing;
