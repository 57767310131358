import FontIcon from 'components/icons';
import PackagingFeaturesModal from 'components/modal/packaging-features';

import { useModal } from 'react-modal-hook';

import { v4 as uuid } from 'uuid';

import './styles/pricing-card.scss';
import './styles/pricing-card-2025.scss';

type PricingCard2025Props = {
  tier: PricingCard2025;
};

export type PricingCard2025 = {
  features: {
    heading: string,
    list: {
      feature: string,
    }[],
  },
  price: string,
  price_label: string,
  title: string,
  highlights: {
    icon: string,
    title: string,
  }[],
}

const PricingCard2025 = ({tier}: PricingCard2025Props) => {
  const [showPackagingFeaturesModal, hidePackagingFeaturesModal] = useModal(() => (
    <PackagingFeaturesModal isOpen={ true } onHide={ hidePackagingFeaturesModal } />
  ));

  const priceDisplay = price => {
    const [dollars, cents] = price.split('.');
    if (cents === '00') {
      return `$${dollars}`; 
    }
    
    return <div className="price">${dollars}<span>.{cents}</span></div>;
  };

  return (
    <div className="column pricing-card-slide pricing-card-slide-2025">
      <div className="card-wrapper">
        <div className="card has-shadow">
          <h3 className="tier-title">{tier.title}</h3>
          <h4 className="price-heading">
            {priceDisplay(tier.price)}
            <span className="price-label"> {tier.price_label}</span>
          </h4>

          <ul className="hightlight-list">
            {tier.highlights && tier.highlights.map(highlight => (
              <li key={ uuid() }>
                <FontIcon icon={highlight.icon} />
                <span className="highlight-title">{highlight.title}</span>
                <FontIcon icon="checkmark" />
              </li>
            ))}
          </ul>          

          <h4 className="features-heading">{tier.features.heading}</h4>
          <ul className="features-list">
            {tier.features && tier.features.list.map(feature => (
              <li key={ uuid() }><FontIcon icon="checkmark" />{feature.feature}</li>
            ))}
            <li className="modal-button" onClick={showPackagingFeaturesModal}><FontIcon icon="info" />See everything else</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PricingCard2025;